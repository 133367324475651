jQuery(document).ready(function($) {

    $('.filter-navigation a').click(function(event) {
        var id = $(this).text().toLowerCase();
        var $text = $('#' + id);

        $('.filter-text').each(function(index, el) {
            if (!$(el).hasClass('hide')) {
                $(el).addClass('hide')
            }
        });

        if ($(this).hasClass('active')) {
            $text.removeClass('hide');
        }
    });

});
